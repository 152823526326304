import type { FC } from 'react'
import 'react-alice-carousel/lib/alice-carousel.css'
import AliceCarousel from 'react-alice-carousel'

import { TBanner } from 'store/features/Setting/type'

import { useAppSelector } from '../../../hooks/useTypedSelector'

import styles from './Slider.module.scss'

const Slider: FC = () => {
  const { settingSlice } = useAppSelector(state => state)

  type Item = {
    banner: TBanner
  }

  const Item = ({ banner }: Item) => (
    <div className={styles.wrapper}>
      <div
        style={{
          backgroundImage: `url(${banner.thumb})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          minHeight: 400,
        }}
        className={styles.wrapper__container}
      >
        <div className={styles.wrapper__container__description}>
          <h1 dangerouslySetInnerHTML={{ __html: banner.h1 }} className={styles.wrapper__container__title}></h1>

          <p dangerouslySetInnerHTML={{ __html: banner.description }} className={styles.wrapper__container__text}></p>
        </div>

        {banner?.product_id && (
          <div className={styles.wrapper__container__footer}>
            <div role='button' className={styles.wrapper__container__button}>
              <a href={`/product/${banner?.product_id}`} className={styles.wrapper__container__button__bg}>
                Перейти до товару
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
  const items = settingSlice.data?.banners?.map((item, index) => <Item key={index} banner={item} />)

  return (
    <>
      <AliceCarousel items={items} mouseTracking disableButtonsControls />
    </>
  )
}

export default Slider
