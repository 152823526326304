import type { FC } from 'react'

import { economData } from './utils'
import { useResponsive } from 'hooks'

import styles from './EconomYourTime.module.scss'
import { useAppSelector } from 'hooks/useTypedSelector'

const EconomYourTime: FC = () => {
  const renderItems = economData.map((element, index) => (
    <div key={index} className={styles.wrapper__footer__item}>
      <h4 className={styles.wrapper__footer__item__title}>{element.title}</h4>

      <p className={styles.wrapper__footer__item__subtitle}>{element.subtitle}</p>
    </div>
  ))

  const { isTablet } = useResponsive()
  const { settingSlice } = useAppSelector(state => state)

  const description: any = settingSlice.data.description

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__container}>
        <div className={styles.wrapper__container__description}>
          <h3 className={styles.wrapper__container__description__title}>
            Онлайн-магазин військових товарів Destroyers!{' '}
          </h3>

          <p
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.wrapper__container__description__subtitle}
          ></p>
        </div>

        {!isTablet && (
          <div className={styles.wrapper__container__image}>
            <img src='images/economTime/phones.png' alt='phones' width='580px' height='480px' />
          </div>
        )}
      </div>

      <div className={styles.wrapper__footer}>{renderItems}</div>

      {isTablet && (
        <div className={styles.wrapper__container__image}>
          <img src='images/economTime/phones.png' alt='phones' width='100%' height='100%' />
        </div>
      )}
    </div>
  )
}

export default EconomYourTime
