import { useEffect, FC, useState } from 'react'
import { RoutesWrapper } from 'router'
import { Footer, Header, MobileNavBar } from 'components'
import styles from './PageLayout.module.scss'
import { useResponsive } from 'hooks'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import { fetchSettingData } from 'store/features/Setting/settingSlice'
import { fetchBasketList } from 'store/features/Basket/basketSlice'
import { fetchWatchList } from 'store/features/Watchlist/watchListSlice'

const PageLayout: FC = () => {
  const { isTablet } = useResponsive()
  const { settingSlice } = useAppSelector(state => state)

  const [scriptAdded, setScriptAdded] = useState(false)

  const head: any = settingSlice?.data?.head

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!scriptAdded) {
      if (head !== null) {
        head.forEach((item: any) => {
          const script = document.createElement('script')
          const PropsItem = item?.props?.[0]

          for (const key in PropsItem) {
            if (PropsItem.hasOwnProperty(key)) {
              const value = PropsItem[key]
              script.setAttribute(key, value)
            }
          }

          script.setAttribute('data-live-chat-id', '64f44c52d10a09501205142a')
          script.async = item.async

          script.src = item.url

          script.onerror = function (event) {
            console.error('Error loading script:', event)
          }

          document.head.prepend(script)
        })

        setScriptAdded(true)
      }
    }
  }, [head, scriptAdded])

  useEffect(() => {
    dispatch(fetchSettingData())
    dispatch(fetchBasketList())
    dispatch(fetchWatchList())
  }, [dispatch])

  return (
    <main className={styles.wrapper}>
      <Header />

      <div className={styles.wrapper__container}>
        <RoutesWrapper />
      </div>

      <Footer />

      {isTablet && <MobileNavBar />}
    </main>
  )
}

export default PageLayout
