import type { FC } from 'react'

import { Link } from 'react-router-dom'

import { StarCircleIcon } from 'assets'

import styles from './Ad.module.scss'
import { Button } from 'components/atom'

const Ad: FC = () => (
  <div className={styles.wrapper}>
    <StarCircleIcon />

    <h3 className={styles.wrapper__title}>Якісна уніформа та спорядження</h3>

    <p className={styles.wrapper__subtitle}>Якісна уніформа та спорядження</p>

    <Link to='/categories'>
      <Button>Каталог </Button>
    </Link>
  </div>
)

export default Ad
